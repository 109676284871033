import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { BillingAccountUsage } from "@warrenio/api-spec/spec.oats.gen";
import { atomFamily } from "jotai/utils";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";

export type Response = BillingAccountUsage[];

export interface Params {
    billing_account_id: number;
}

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["charging/usage"] : ["charging/usage", params.billing_account_id];
}

export function getQuery(client: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { billing_account_id } = params;
            return getResponseData(
                await client.GET("/charging/usage", {
                    signal,
                    params: { query: { billing_account_id } },
                }),
            );
        },
    });
}

export const queryAtom = atomFamily((billing_account_id: number) =>
    atomFromStandardQueryOptions(getQuery, { billing_account_id }),
);

export function useSuspense(billing_account_id: number) {
    return useSuspenseQueryAtom(queryAtom(billing_account_id));
}
